import React , { useState, useEffect } from 'react';
import '../styles/sampleImageItem.css';
import disabled_icon from '../assets/disabled.png';
import axios from 'axios';
import Modal from 'react-modal'; 
import apiUrl from '../config.js';
import green_tick from '../assets/green_tick.png'; // Path to your green tick icon
import red_cross from '../assets/red_cross.png'; // Path to your red cross icon
import { useTranslation } from 'react-i18next';


const SampleGermImageRow = ({sample, handleCapacityUpdate, sampleItems, index}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fullSizedImage, setFullSizedImage] = useState(null);
  const [disabled, setDisable] = useState(sample.is_disabled)

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImgType, setCurrentImgType] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    if (modalIsOpen) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalIsOpen, currentIndex, currentImgType]);

  const openModal = async (processed) => {
    setCurrentImgType(processed)
    try {
      
      const response = await axios.get(apiUrl + `/get-full-size-image-germination/${sampleItems[index].sample_id}/${sampleItems[index].file_name}`, {
        params: {
          is_processed : (processed === 'processed')
          
        }
        
      });

      const fullSizedImageData = response.data;
      setFullSizedImage(fullSizedImageData.data);
      setCurrentIndex(index)
      setModalIsOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const nextImage = async (processed, newIndex) => {
    try {      
      
      const response = await axios.get(apiUrl + `/get-full-size-image-germination/${sampleItems[newIndex].sample_id}/${sampleItems[newIndex].file_name}`, {
        params: {
          is_processed : (processed === 'processed')
        }
        
      });
      const fullSizedImageData = response.data;
      setFullSizedImage(fullSizedImageData.data);
      
      setModalIsOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setFullSizedImage(null);
  };

   // Helper function to decide which icon to show
   const getStatusIcon = () => {
    if (sample.percentage_active > 25) {
      return green_tick;
    } else {
      return red_cross;
    }
  };

  const toggleDisabled = async () => {
    try {
      
      const response = await axios.post(apiUrl + `/toggle-disable-image-germ/${sample.sample_id}/${sample.image_id}`, {
      });

      setDisable(!disabled)
      handleCapacityUpdate()
      
    } catch (error) {
      console.error(error);
    }
  };

  let buttonClass = "edit-icon enabled";

  const handleNavigate = (direction) => {
    const newIndex = (currentIndex + direction + sampleItems.length) % sampleItems.length;
    setCurrentIndex(newIndex);
    nextImage(currentImgType, newIndex);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowLeft') {
      handleNavigate(-1);
    } else if (event.key === 'ArrowRight') {
      handleNavigate(1);
    }
  };
  
  return (
    <div className={`sample-image-row ${disabled ? 'disabled' : ''}`}>
      <div className="sample-image-thumbnails">
      <img id="image" className="thumbnail-image" src={`data:image/jpeg;base64,${sample.thumbnail}`} onClick={(event) => openModal(event.target.id)} />
      </div>
      <div className="sample-image-thumbnails">
        <img id="processed" className="thumbnail-image" src={`data:image/jpeg;base64,${sample.processed}`} onClick={(event) => openModal(event.target.id)} />
      </div>
      <div className="sample-image-icons">
      {t('image_row_active')} {Number(sample.percentage_active).toFixed(2)} %
    </div>
    <div className="sample-image-icons">
      <img src={getStatusIcon()} alt={sample.percentage_active > 50 ? 'Green tick' : 'Red cross'} className="status-icon" />
    </div>
      
    <div className="sample-image-filename">Filename {sample.file_name}</div>
    <div className="sample-image-icons">
      <button className={buttonClass} onClick={toggleDisabled}>
        <img className="toggle-image" src={disabled_icon} alt="Edit" />
      </button>
      </div>
      
      <Modal
      
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Full-Sized Image"
        ariaHideApp={false}
        className="modal-content">
        
        <div className="modal-image-container">
          <div className='modal-data-container'>
            <div className="filename-text">{sampleItems[currentIndex].file_name}</div>
            <div className="modal-percentage">{Number(sampleItems[currentIndex].percentage_active).toFixed(2)} % Active</div>
          </div>
          <button onClick={() => handleNavigate(-1)} className="modal-navigation-left">
            <i ></i>
          </button>
          {fullSizedImage && <img src={`data:image/jpeg;base64,${fullSizedImage}`} alt="Full-Sized" style={{width: '80%', height: '80%', objectFit: 'contain'}} />}
          <button onClick={() => handleNavigate(1)} className='modal-navigation-right'>
            <i ></i>
          </button>
          
          <button onClick={closeModal} className='close-button'>{t('image_row_close')}</button>
        </div>
        
      </Modal>
    </div>
  );
};

export default SampleGermImageRow;
