import React, { useState } from 'react';
import Button from 'bootstrap';
import axios from 'axios';
import Card from './Card.js';
import '../styles/styles.css'
import apiUrl from '../config.js';
import { useTranslation } from 'react-i18next';


const AddImages = ({activeSampleData,onSampleProcess}) => {

  const [thumbnails, setThumbnails] = useState([]);
  const [processingButton, setProcessingButton] = useState(false); // Add this state
  const [isUploading, setUploading] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const { t } = useTranslation();

  const handleImageUpload = async (event) => {
    const files = event.target.files;
    const uploadPromises = [];
  
    setUploading(true); // Start the upload status
  
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const { thumbnailDataUrl, thumbnailFile } = await createThumbnail(file);
      setThumbnails((prevThumbnails) => [...prevThumbnails, thumbnailDataUrl]);
  
      // Instead of uploading immediately, push the promise into an array
      uploadPromises.push(uploadImage(file, thumbnailFile, file.name));
    }
  
    // Use Promise.all to wait for all uploads to complete before setting the uploading to false
    Promise.all(uploadPromises)
      .then(() => {
        setUploading(false); // All uploads are done, set uploading to false
      })
      .catch((error) => {
        // Handle the error if any of the uploads fail
        setUploading(false);
      });
  };

  const createThumbnail = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;

        image.onload = () => {
          const maxThumbSize = 100;
          const aspectRatio = image.width / image.height;
          let thumbWidth = maxThumbSize;
          let thumbHeight = maxThumbSize;

          if (aspectRatio > 1) {
            thumbHeight = maxThumbSize / aspectRatio;
          } else {
            thumbWidth = maxThumbSize * aspectRatio;
          }

          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = thumbWidth;
          canvas.height = thumbHeight;
          context.drawImage(image, 0, 0, thumbWidth, thumbHeight);

          const extension = file.name.split('.').pop();
          const thumbnailFileName = `${file.name.split('.')[0]}_thumbnail.${extension}`;
          const thumbnailDataUrl = canvas.toDataURL(`image/${extension}`);
          const thumbnailFile = dataURLtoFile(thumbnailDataUrl, thumbnailFileName);
          resolve({ thumbnailDataUrl, thumbnailFile });
        };
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const uploadImage = async (file, thumbnailFile, fileName) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('thumbnail', thumbnailFile);
    formData.append('fileName', fileName);

    // Retrieve the token from localStorage
  const token = localStorage.getItem("token");

  // Include the token in the request headers
  const headers = {
    'Authorization': `Bearer ${token}`
  };
  
    // Return the axios promise directly
    return axios.post(apiUrl + (activeSampleData.type === 'New Sample' ? `/upload-image/${activeSampleData.sample_id}` : `/upload-germ-image/${activeSampleData.sample_id}`), formData, { headers: headers })
      .then(response => {
        console.log('Image uploaded successfully');
        // Your existing success logic here
      });
  };

  const processImages = async () => {
    setProcessingButton(true); // Disable the button and show loading indicator

    try {
      const formData = new FormData();
      formData.append('sampleName', activeSampleData.name);
      formData.append('date', activeSampleData.date);
      formData.append('lote', activeSampleData.lote);

        // Retrieve the token from localStorage
      const token = localStorage.getItem("token");

      // Include the token in the request headers
      const headers = {
        'Authorization': `Bearer ${token}`
      };


      if (activeSampleData.type == 'New Sample') {
        const response = await axios.post(apiUrl + `/process-images/${activeSampleData.sample_id}`, formData, { headers: headers });
      } else {
        const response = await axios.post(apiUrl + `/process-germination-images/${activeSampleData.sample_id}`, formData, { headers: headers });
      }

      // Start polling
        const checkProcessingStatus = setInterval(async () => {
            const response = await axios.get(apiUrl + '/processing-status', {headers: headers});
            setProgressPercentage(response.data.progress_percentage);

            if (response.data.status === "done") {
                  setTimeout(() => {
                    clearInterval(checkProcessingStatus);
                    onSampleProcess(activeSampleData.type, activeSampleData.sample_id);
                    setProcessingButton(false); 
                }, 2000);
                
            }
        }, 2000); // Check every 2 seconds


      
    } catch (error) {
      console.error('Error initiating image processing:', error);
    }
    
  };


  

    return (
      <div className="images-container">
        <h3>{t('add_images_title')}: {activeSampleData.name}</h3>
        <h4>{t('add_images_add')}</h4>
        <div className="add-buttons">
          <p className='paragraph'>{t('add_images_add_files')}</p>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageUpload}
            style={{ display: 'none' }}
            id="file-upload"
          />
          <button className='process-button btn btn-primary'  onClick={() => document.getElementById('file-upload').click()}>
            {t('add_images_upload_files')}
          </button>
    
          <p className='paragraph'>{t('add_images_add_folder')}</p>
          <input
            type="file"
            webkitdirectory="true"
            directory="true"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
            id="folder-upload"
          />
          <button className='process-button btn btn-primary' onClick={() => document.getElementById('folder-upload').click()}>
            {t('add_images_upload_folder')}
          </button>
        </div>
        <div className="thumbnails">
          {thumbnails.map((thumbnail, index) => (
            <Card key={index} thumbnail={thumbnail} />
          ))}
        </div>
      <button 
        className={`process-button btn btn-primary ${isUploading || !thumbnails.length ? 'disabled' : ''}`} 
        onClick={processImages}>
        {isUploading ? (
            <div className="spinner-wrapper">
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {t('add_images_uploading')}
            </div>
        ) : processingButton ? (
            <div className="spinner-wrapper">
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {t('add_images_processing')} {progressPercentage}%
            </div>
        ) : t('add_images_process_sample')}
    </button>
    </div>
  );
};

export default AddImages;
