import React, { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import axios from 'axios';
import apiUrl from '../config.js';
import '../styles/sampleImageItem.css';

function ContourEdition({closeEdition, contours, img, sample, handleHydration, handleClose}) {
    
    const canvasRef = useRef(null);
    const canvasInstanceRef = useRef(null); 

    const endoContour = contours.endo_contour;
    const nhContour = contours.nh_contour;

    const base64Prefix = "data:image/png;base64,";  // Assuming it's a PNG image. Change if necessary.
    const completeBase64Image = base64Prefix + img;

    const handleSave = async () => {
        const canvasInstance = canvasInstanceRef.current;
        if (canvasInstance) {
            const allObjects = canvasInstance.getObjects();
            const savedContours = {};
    
            const roundPointsToInt = (points) => {
                return points.map(point => ({
                    x: Math.round(point.x),
                    y: Math.round(point.y)
                }));
            };
    
            if (allObjects[1] && allObjects[1].type === 'polygon') {
                savedContours.endoContour = roundPointsToInt(allObjects[1].get('points'));
            } else {
                console.log("Endo Contour not found or not a polygon");
            }
    
            if (allObjects[2] && allObjects[2].type === 'polygon') {
                savedContours.nhContour = roundPointsToInt(allObjects[2].get('points'));
            } else {
                console.log("NH Contour not found or not a polygon");
            }
    
            try {
                const response = await axios.post(apiUrl + `/update-contours/${sample.sample_id}/${sample.image_id}`, {
                        contours: JSON.stringify(savedContours) // Convert contours to JSON string                    
                });
                

                handleHydration()
                handleClose()
                
            } catch (error) {
                console.error("Error sending contours:", error);
            }
        }
    };
    
    


    useEffect(() => {

        const canvas = new fabric.Canvas(canvasRef.current);
        canvasInstanceRef.current = canvas;

        fabric.Image.fromURL(completeBase64Image, (img) => {
            img.set({
                
                lockMovementX: true,  // Lock horizontal movement
                lockMovementY: true,  // Lock vertical movement
                hasControls: false,
                selectable: false,  
                hasBorders: false,
                click:false
            });
            canvas.sendToBack(img);

        });

        const endo_polygon = new fabric.Polygon(endoContour, {
            
            fill: 'rgba(250, 50, 50, 0.2)',
            strokeWidth: 4,
            stroke: 'green',
            scaleX: 1,
            scaleY: 1,
            objectCaching: false,
            transparentCorners: false,
            cornerColor: 'blue',
        });
        const nh_polygon = new fabric.Polygon(nhContour, {
            
            fill: 'rgba(50, 250, 50, 0.2)',
            
            strokeWidth: 4,
            stroke: 'red',
            scaleX: 1,
            scaleY: 1,
            objectCaching: false,
            transparentCorners: false,
            cornerColor: 'green',
        });

        canvas.add(endo_polygon);
        canvas.add(nh_polygon);

        function polygonPositionHandler(dim, finalMatrix, fabricObject) {
            var x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x),
                  y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y);
              return fabric.util.transformPoint(
                  { x: x, y: y },
            fabric.util.multiplyTransformMatrices(
              fabricObject.canvas.viewportTransform,
              fabricObject.calcTransformMatrix()
            )
              );
          }

          function actionHandler(eventData, transform, x, y) {
            const R = 50; // This determines the range within which neighbors are affected. Adjust as needed.
        
            var polygon = transform.target,
                currentControl = polygon.controls[polygon.__corner],
                mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
                polygonBaseSize = getObjectSizeWithStroke(polygon),
                size = polygon._getTransformedDimensions(0, 0),
                finalPointPosition = {
                    x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
                    y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
                };
            
            // Store the original position of the dragged vertex
            const originalDraggedPosition = {
                x: polygon.points[currentControl.pointIndex].x,
                y: polygon.points[currentControl.pointIndex].y
            };
        
            // Update the dragged vertex position
            polygon.points[currentControl.pointIndex] = finalPointPosition;
        
            // Calculate the offset of the dragged vertex
            const offsetX = finalPointPosition.x - originalDraggedPosition.x;
            const offsetY = finalPointPosition.y - originalDraggedPosition.y;
        
            // Adjust neighboring vertices based on their distance from the dragged vertex
            polygon.points.forEach((point, index) => {
                if (index !== currentControl.pointIndex) { // Ensure we're not modifying the dragged vertex again
                    const dx = point.x - finalPointPosition.x;
                    const dy = point.y - finalPointPosition.y;
                    const distance = Math.sqrt(dx * dx + dy * dy);
        
                    if (distance < R) {
                        const factor = (R - distance) / R; // Calculate a factor based on distance; closer points have a higher factor.
                        
                        point.x += offsetX * factor;
                        point.y += offsetY * factor;
                    }
                }
            });
        
            return true;
        }
        
    
        // define a function that can keep the polygon in the same position when we change its
        // width/height/top/left.
        function anchorWrapper(anchorIndex, fn) {
            return function(eventData, transform, x, y) {
            var fabricObject = transform.target,
                absolutePoint = fabric.util.transformPoint({
                    x: (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
                    y: (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y),
                }, fabricObject.calcTransformMatrix()),
                actionPerformed = fn(eventData, transform, x, y),
                newDim = fabricObject._setPositionDimensions({}),
                polygonBaseSize = getObjectSizeWithStroke(fabricObject),
                newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x,
                    newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
            //fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
            return actionPerformed;
            }
        }

      function getObjectSizeWithStroke(object) {
		var stroke = new fabric.Point(
			object.strokeUniform ? 1 / object.scaleX : 1, 
			object.strokeUniform ? 1 / object.scaleY : 1
		).multiply(object.strokeWidth);
		return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
	}
	
	var poly = canvas.getObjects()[0];
	
	
    var poly2 = canvas.getObjects()[1];
    
    
    var lastControl = poly.points.length - 1;
    poly.cornerStyle = 'circle';
    poly.cornerColor = 'rgba(0,0,255,0.5)';
	poly.controls = poly.points.reduce(function(acc, point, index) {
		acc['p' + index] = new fabric.Control({
					positionHandler: polygonPositionHandler,
					actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler),
					actionName: 'modifyPolygon',
					pointIndex: index
		});
		return acc;
	}, { });

    // Add this for the second polygon
    var lastControl2 = poly2.points.length - 1;
    poly2.cornerStyle = 'circle';
    poly2.cornerColor = 'rgba(0,0,255,0.5)';
    poly2.controls = poly2.points.reduce(function(acc, point, index) {
        acc['p' + index] = new fabric.Control({
                    positionHandler: polygonPositionHandler,
                    actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl2, actionHandler),
                    actionName: 'modifyPolygon',
                    pointIndex: index
        });
        return acc;
    }, { });
		
	poly.hasBorders = true;
    poly2.hasBorders = true;
	canvas.requestRenderAll();
	
    // Re-render the canvas to show the updated polygon with control points
    

    }, []);

    return <div className="contour-edition-container">

            <div className="filename-header">
                    {sample.file_name}
                </div>
            <canvas ref={canvasRef} width={1024} height={1024}></canvas>
            <div className="buttons-container">
                    <button className="button btn-primary" onClick={handleSave}>Save</button>
                    <button className="button btn-secondary" onClick={closeEdition}>Cancel</button>
            </div>
        </div>;
}

export default ContourEdition;