import '../styles/varietiesManager.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiUrl from '../config.js';
import { useTranslation } from 'react-i18next';

const VarietiesManager = () => {
    const [varieties, setVarieties] = useState([]);
    const [newVariety, setNewVariety] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const [showDescription, setShowDescription] = useState({}); // Track which descriptions are shown
    const [editDescription, setEditDescription] = useState({}); // Track which descriptions are being edited
    const [editName, setEditName] = useState({}); // Track which names are being edited
    const [message, setMessage] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        const fetchVarieties = async () => {
            try {
                const response = await axios.get(apiUrl + '/varieties');
                setVarieties(response.data); // This should set varieties to [{ id, name, description }]
            } catch (error) {
                console.error(error);
            }
        };
        fetchVarieties();
    }, []);

    const handleAddVariety = async () => {
        if (newVariety.trim() === '') return;
        try {
            const response = await axios.post(apiUrl + '/variety', { name: newVariety, description: newDescription });
            setVarieties([response.data, ...varieties]); // Add the new variety to the beginning of the list
            setNewVariety('');
            setNewDescription('');
        } catch (error) {
            console.error(error);
        }
    };

    const handleRemoveVariety = async (variety) => {
        try {
            const response = await axios.delete(apiUrl + `/variety/${variety.id}`);
            if (response.data.message) {
                setVarieties(varieties.filter(v => v.id !== variety.id)); // Update based on variety id
                setMessage(response.data.message);
            } else if (response.data.error) {
                setMessage(response.data.error);
            }
        } catch (error) {
            setMessage(t('variety_error'));
            console.error(error);
        }
    };

    const toggleDescription = (id) => {
        setShowDescription(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const handleEditDescription = (id, description) => {
        setEditDescription(prevState => ({
            ...prevState,
            [id]: description
        }));
    };

    const saveDescription = async (id) => {
        try {
            const updatedVariety = varieties.find(v => v.id === id);
            updatedVariety.description = editDescription[id];
            await axios.put(apiUrl + `/variety/${id}`, { description: editDescription[id] });
            setVarieties(varieties.map(v => (v.id === id ? updatedVariety : v)));
            setEditDescription(prevState => ({
                ...prevState,
                [id]: undefined
            }));
        } catch (error) {
            console.error(error);
        }
    };

    const cancelEditDescription = (id) => {
        setEditDescription(prevState => ({
            ...prevState,
            [id]: undefined
        }));
    };

    const handleEditName = (id, name) => {
        setEditName(prevState => ({
            ...prevState,
            [id]: name
        }));
    };

    const saveName = async (id) => {
        try {
            const updatedVariety = varieties.find(v => v.id === id);
            updatedVariety.name = editName[id];
            await axios.put(apiUrl + `/variety-name/${id}`, { name: editName[id] });
            setVarieties(varieties.map(v => (v.id === id ? updatedVariety : v)));
            setEditName(prevState => ({
                ...prevState,
                [id]: undefined
            }));
        } catch (error) {
            console.error(error);
        }
    };

    const cancelEditName = (id) => {
        setEditName(prevState => ({
            ...prevState,
            [id]: undefined
        }));
    };

    return (
        <div className='varieties-manager'>
            <h3>{t('variety_menu')}</h3>
            {message && <p className="message">{message}</p>}
            <input
                type="text"
                value={newVariety}
                onChange={e => setNewVariety(e.target.value)}
                placeholder={t('variety_new_variety')}
            />
            <input
                type="text"
                value={newDescription}
                onChange={e => setNewDescription(e.target.value)}
                placeholder={t('variety_new_description')}
            />
            <button onClick={handleAddVariety}>{t('variety_add')}</button>
            <div className='scrollable-container'>
                <ul>
                    {varieties.map(variety => (
                        <li key={variety.id}>
                            <div className="variety-name">
                                {editName[variety.id] !== undefined ? (
                                    <>
                                        <input
                                            className='variety-label'
                                            type="text"
                                            value={editName[variety.id]}
                                            onChange={(e) => handleEditName(variety.id, e.target.value)}
                                        />
                                        <div className="editNameGroup">
                                            <button className="saveName" onClick={() => saveName(variety.id)}>{t('Save')}</button>
                                            <button className="cancelName" onClick={() => cancelEditName(variety.id)}>{t('Cancel')}</button>
                                        </div>
                                    </>
                                ) : (
                                    <div className="name-label">
                                        {variety.name}
                                    </div>
                                )}
                                <div className="button-group">
                                    <button className="description-button" onClick={() => toggleDescription(variety.id)}>
                                        {showDescription[variety.id] ? '-' : '+'}
                                    </button>
                                    <button className="edit-name" onClick={() => handleEditName(variety.id, variety.name)}>{t('variety_edit')}</button>
                                    <button onClick={() => handleRemoveVariety(variety)}>{t('variety_remove')}</button>
                                </div>
                            </div>
                            <div className={`variety-description ${showDescription[variety.id] ? 'show' : ''}`}>
                                {editDescription[variety.id] !== undefined ? (
                                    <>
                                        <input
                                            type="text"
                                            value={editDescription[variety.id]}
                                            onChange={(e) => handleEditDescription(variety.id, e.target.value)}
                                        />
                                        <button onClick={() => saveDescription(variety.id)}>{t('Save')}</button>
                                        <button onClick={() => cancelEditDescription(variety.id)}>{t('Cancel')}</button>
                                    </>
                                ) : (
                                    <>
                                        {variety.description || t('variety_no_description')}
                                        <button onClick={() => handleEditDescription(variety.id, variety.description || '')}>{t('variety_edit')}</button>
                                    </>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default VarietiesManager;
