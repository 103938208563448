import React , { useState, useEffect  } from 'react';
import '../styles/sampleImageItem.css';
import view_icon from '../assets/view_icon.jpg';
import edit_icon from '../assets/edit.png';
import disabled_icon from '../assets/disabled.png';
import axios from 'axios';
import Modal from 'react-modal'; 
import ContourEdition from './ContourEdition';
import apiUrl from '../config.js';
import { useTranslation } from 'react-i18next';

const SampleImageRow = ({sample, handleHydrationUpdate, sampleItems, index}) => {

  const { t } = useTranslation();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [fullSizedImage, setFullSizedImage] = useState(null);
  const [contours, setContours] = useState(null);
  const [image, setImage] = useState(null);
  const [disabled, setDisable] = useState(sample.is_disabled)
  const [edited, setEdited] = useState(sample.edited); // Add edited state

  const [imageIndex, setImageIndex] = useState(sample.image_index);
  const [imageHydration, setImageHydration] = useState(sample.hydration_percentage);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImgType, setCurrentImgType] = useState(0);

  useEffect(() => {
    if (modalIsOpen) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalIsOpen, currentIndex, currentImgType]);

  const openModal = async (processed) => {
    setCurrentImgType(processed)
    try {      
      
      const response = await axios.get(apiUrl + `/get-full-size-image/${sampleItems[index].sample_id}/${sampleItems[index].file_name}`, {
        params: {
          is_processed : (processed === 'processed')
          
        }
        
      });
      const fullSizedImageData = response.data;
      setFullSizedImage(fullSizedImageData.data);
      setCurrentIndex(index)
      setModalIsOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const nextImage = async (processed, newIndex) => {
    try {      
      
      const response = await axios.get(apiUrl + `/get-full-size-image/${sampleItems[newIndex].sample_id}/${sampleItems[newIndex].file_name}`, {
        params: {
          is_processed : (processed === 'processed')
          
        }
        
      });
      const fullSizedImageData = response.data;
      setFullSizedImage(fullSizedImageData.data);
      
      setModalIsOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setFullSizedImage(null);
  };
  
  const getColorByHumidity = (index) => {
    switch (true) {
      case index == 'A':
        return '#1E5162'; // Dark Blue for high humidity
      case index == 'B':
        return '#338BA8'; // Medium Blue
      case index == 'C':
        return '#67B7D1'; // Light Blue
      case index == 'D':
        return '#ADD8E6'; // Lightest Blue for low humidity
      case index == 'white':
        return index  
    }
  };

  const handleHydration = () => {
    updateIndex()
    handleHydrationUpdate()
  };

  const circleBackgroundColor = getColorByHumidity(imageIndex);

  const circleTextColor = imageIndex === 'White' ? 'black' : 'white';

  const openEdit = async () => {
    
    try {
      if (!image) {
        const response = await axios.get(apiUrl + `/get-contours/${sample.sample_id}/${sample.file_name}/${sample.image_id}`, {
        });
        const data = response.data;
        setContours(data.contours)
        setImage(data.image.data)
      }
      setEditIsOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const updateIndex = async () => {
    try {
      
      const response = await axios.get(apiUrl + `/get-image-index/${sample.image_id}`, {
      });
      
      const data = response.data;
    
      setImageIndex(data.data.image_index)
      setImageHydration(data.data.hydration_percentage)
      setEdited(true)
    
    } catch (error) {
      console.error(error);
    }
  };

  const closeEdition = () => {
    setEditIsOpen(false);
    //setContours(null);
    setImage(null);
  };

  const toggleDisabled = async () => {
    try {
      
      const response = await axios.post(apiUrl + `/toggle-disable-image/${sample.sample_id}/${sample.image_id}`, {
      });

      setDisable(!disabled)
      handleHydrationUpdate()
      
    } catch (error) {
      console.error(error);
    }
  };

  let buttonClass = "edit-icon enabled";

  const indexStyle = {
    fontSize: imageIndex === 'White' ? '12px' : 'inherit' // 'inherit' will keep the font size as it is if the condition is not met
  };

  const handleNavigate = (direction) => {
    const newIndex = (currentIndex + direction + sampleItems.length) % sampleItems.length;
    setCurrentIndex(newIndex);
    nextImage(currentImgType, newIndex);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowLeft') {
      handleNavigate(-1);
    } else if (event.key === 'ArrowRight') {
      handleNavigate(1);
    }
  };


  return (
    <div className={`sample-image-row ${disabled ? 'disabled' : ''}`}>
      <div className="sample-image-thumbnails">
      <img id="image" className="thumbnail-image" src={`data:image/jpeg;base64,${sample.thumbnail}`} onClick={(event) => openModal(event.target.id)} />
      </div>
      <div className="sample-image-thumbnails">
        <img id="processed" className="thumbnail-image" src={`data:image/jpeg;base64,${sample.processed}`} onClick={(event) => openModal(event.target.id)} />
      </div>
      {/*<div className="sample-image-icons">
        <img className="image-icon" src={view_icon}></img>
      </div>*/}
      <div className="sample-image-icons">
        <img className="edit-icon" src={edit_icon} onClick={() => openEdit()}></img>
        {edited && <div className="edited-dot"></div>}
      </div>
      <div className="sample-image-index">
        
        &nbsp;<div className="circle" style={{ backgroundColor: circleBackgroundColor }}> <span className="index-value" style={{ color: circleTextColor, ...indexStyle }}> {imageIndex}</span>
          
        </div>
      </div>
      <div className="sample-image-hydration">{t('image_row_hydration_percentage')} {imageHydration} %</div>
      <div className="sample-image-filename"> {sample.file_name}</div>
      <div className="sample-image-icons">
      <button className={buttonClass} onClick={toggleDisabled}>
        <img className="toggle-image" src={disabled_icon} alt="Edit" />
      </button>
      </div>
      <Modal
      
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Full-Sized Image"
        ariaHideApp={false}
        className="modal-content">
        
        <div className="modal-image-container">
        <div className='modal-data-container'>
            <div className="filename-text">{sampleItems[currentIndex].file_name}</div>
            <div className="modal-percentage">{t('image_row_hydration_percentage')} {Number(sampleItems[currentIndex].hydration_percentage).toFixed(2)} % </div>
          </div>
          <button onClick={() => handleNavigate(-1)} className="modal-navigation-left">
            <i ></i>
          </button>
          {fullSizedImage && <img src={`data:image/jpeg;base64,${fullSizedImage}`} alt="Full-Sized" style={{width: '80%', height: '80%', objectFit: 'contain'}} />}
          <button onClick={() => handleNavigate(1)} className='modal-navigation-right'>
            <i ></i>
          </button>
          
          <button onClick={closeModal} className='close-button'>{t('image_row_close')}</button>
        </div>
      </Modal>
      <Modal
        isOpen={editIsOpen}
        onRequestClose={closeEdition}
        contentLabel="Contours Edition"
        ariaHideApp={false}
        className="modal-content">
        
        <div className="modal-image-container">
          <ContourEdition closeEdition ={closeEdition} contours={contours} img={image} sample={sample} handleHydration={handleHydration} handleClose={closeEdition}></ContourEdition>
        </div>
        
      </Modal>
    </div>
  );
};

export default SampleImageRow;
