import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/users.css'
import apiUrl from '../config.js';
import { useTranslation } from 'react-i18next';

function ManageUsers() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [users, setUsers] = useState([]);
    const [editMode, setEditMode] = useState(null);

    const [selectedUserId, setSelectedUserId] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();

    const handleNewUser = () => {
        setEmail('');
        setPassword('');
        setUsername('');
        setSelectedUserId(null);
        setEditMode(false);
    };
    
    async function fetchUsers() {
        setIsLoading(true); // Start loading before fetching data
        try {
            const response = await axios.get(apiUrl + '/get-users', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setUsers(response.data);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
        setIsLoading(false); // Stop loading after fetching data
    }

    useEffect(() => {
        fetchUsers();
    }, []);
    
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let response;
    
            if (editMode && selectedUserId) {
                // Update user logic
                response = await axios.put(apiUrl + `/update-user/${selectedUserId}`, {
                    email,
                    password,
                    username
                });
            } else {
                // Create user logic
                response = await axios.post(apiUrl + '/create-user', {
                    email,
                    password,
                    username
                });
            }
    
            const data = response.data;
    
            if (response.status === 201 || response.status === 200) {
                setPassword('');
                setEmail('');
                setUsername('');
                setSuccessMessage(editMode ? t('users_updated_success') : t('users_created_success'));
                fetchUsers();
            } else {
                console.error("Error:", data.message);
                setErrorMessage(data.message);
            }
    
        } catch (error) {
            console.error("Error:", error);
            const serverErrorMessage = error.response && error.response.data && error.response.data.message;
            const errorMessageToShow = serverErrorMessage ;
            console.error("There was an error sending the data:", errorMessageToShow, serverErrorMessage);
            setSuccessMessage('');
            setErrorMessage('');
        }
    };

    const handleDelete = async (userId) => {
        const confirmDelete = window.confirm(t('users_confirm_delete')); // Add this line
        if (!confirmDelete) return; // Add this line
    
        try {
            const response = await axios.delete(apiUrl + `/delete-user/${userId}`);
            const updatedUsers = users.filter(user => user.id !== userId);
            setUsers(updatedUsers);
            // Handle the response if it's successful
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    };

    const handleEdit = (user) => {
        setEmail(user.email);
        setUsername(user.username);
        setSelectedUserId(user.id);
        setEditMode(true);
    };

    return (
        <div className='users-container'>
            {isLoading ? (
                <div className="spinner-container"> {/* Center the spinner */}
                <div className="spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
             </div>
            ) : (
                <div className='content'>
                    <div className="users-list">
                            <h3>{t('users_list')}</h3>
                            <button className=' btn btn-primary newuser-button' onClick={handleNewUser}>{t('users_new')} </button> {/* <-- Add this button */}
                            <table>
                            <thead>
                                <tr>
                                    <th>{t('users_email')}</th>
                                    <th>{t('users_username')}</th>
                                    <th>{t('users_actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map(user => (
                                    <tr key={user.id}>
                                    <td>{user.email}</td>
                                        <td>{user.username}</td>
                                        <td>
                                            <button className='btn btn-primary edit-button' onClick={() => handleEdit(user)}>{t('users_edit')}</button>
                                            <button className='btn btn-primary edit-button' onClick={() => handleDelete(user.id)}>{t('users_delete')}</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            </table>
                    </div>
                    <div className="create-user-form">
                        {editMode !== null && ( // Form will only show up if editMode is not null
                            <>
                                <h3>{editMode ? t('users_edit') : t('users_create')}</h3>

                                {successMessage && <div className="success-message">{successMessage}</div>}
                                {errorMessage && <div className="error-message">{errorMessage}</div>}

                                <form name='form1' onSubmit={handleSubmit}>
                                <div className="form-field">
                                <label>{t('users_email')} 
                                    <input id='email' type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                </label>
                                
                            </div>
                            <div className="form-field">
                                <label>{t('users_username')} 
                                    <input id='username' type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
                                </label>
                                
                            </div>
                            <div className="form-field">
                                <label>{t('users_password')} 
                                    <input id='password' type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                </label>
                                
                            </div>
                            <button className='btn btn-primary' type="submit">{editMode ? t('users_update') : t('users_new')}</button>
                                </form>
                            </>
                        )}
                    </div>
                </div>
            )}   
        </div>
        
        

    );
};

export default ManageUsers;
