const apiUrl2 = 'https://3.142.206.74/api';
const apiUrl = 'https://app.malteriaoriental.com.uy/api';
const localhost = 'http://localhost:5000/api';

const capacity_threshold = 25;

export default apiUrl;



