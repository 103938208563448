import React, { useState } from 'react';
import SampleImageRow from './SampleImageRow.js';
import '../styles/sampleImageItem.css'
import axios from 'axios' 
import apiUrl from '../config.js';
import { useTranslation } from 'react-i18next';

const SampleImagesContainer = ({sampleItems, sampleInfo}) => {

  const { t } = useTranslation();

  const [hydration, setHydration] = useState(sampleInfo[0].hydration_percentage);

  const handleHydrationUpdate = async () => {

    
    try {
      
      let sample_id = sampleInfo[0].sample_id
      const response = await axios.get(apiUrl + `/get-sample-hydration/${sample_id}`);
      setHydration(response.data.data.hydration_percentage);
  
    } catch (error) {
      console.error(error);
    }

  }

  const handleDownloadCSV = async () => {
    
    try {
      const sample_id = sampleInfo[0].sample_id;
      const response = await axios.get(apiUrl + `/download-sample-csv/${sample_id}`, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `sample_${sample_id}.csv`;
      link.click();
    } catch (error) {
      console.error(error);
    }
  };

  return (

    <div>
      <div className="sample-view-header">
        <div className='sample-view-info'>
          <div className="sample-info-name">
            <h4>{t('sample_info_name')} {sampleInfo[0].sample_name}</h4>
          </div>
          <div className="sample-info-batch">
            <h5>{t('sample_info_batch')} {sampleInfo[0].batch}</h5>
          </div>
          <div className="sample-info-batch">
            <h5>{t('sample_info_plant')} {sampleInfo[0].plant}</h5>
          </div>
          <div className="sample-info-batch">
            <h5>{t('sample_info_variety')} {sampleInfo[0].variety}</h5>
          </div>
        </div>
        <div className="sample-info-hydration">
          <h4>{t('sample_info_hydration')} {hydration} %</h4>
          <h5>{t('sample_info_username')} {sampleInfo[0].username}</h5>
        </div>
      </div>
      <div className="sample-images-container">
      {sampleItems.map((sample, index) => (
        <SampleImageRow key={sample.image_id} sample={sample} handleHydrationUpdate={handleHydrationUpdate}
                        sampleItems={sampleItems} index={index}
        />
      ))}
      </div>
      <div className="actions-container">
        <button className='btn btn-primary' onClick={handleDownloadCSV}>{t('sample_info_download')}</button>
        {sampleInfo.notes && (
          <div className='notes-container'>
            <h6>{t('sample_info_notes')}</h6>
            <textarea className='notes'           
              value={sampleInfo.notes}
              readOnly // Making it read-only if it's just for display
              rows="4" 
              cols="50"
            />
          </div>
        )}
      </div>
      
    </div>
  );
};

export default SampleImagesContainer;
