import React, { useState } from 'react';
import '../styles/home.css'
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();
  
  return (
   <div className="home-container">
        <h1>{t('welcome')}</h1>
        <h2>{t('welcome_description')}</h2>
        <h3>{t('welcome_submenu')}</h3>
   </div>
  );
};

export default Home;
