import React, { useState } from 'react';
import SampleGerminationRow from './SampleGerminationRow.js';
import SampleGerminationInfo from './SampleGerminationInfo.js';
import axios from 'axios';
import '../styles/samplesView.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import apiUrl from '../config.js';
import { useTranslation } from 'react-i18next';
import Papa from 'papaparse';

const SamplesGerminationContainer = ({ sampleItems, totalPage }) => {
  const [openSample, setOpenSample] = useState(null);
  const [sampleRowItems, setSampleRowItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(totalPage);
  const [samples, setSamples] = useState(sampleItems);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { t } = useTranslation();

  const handleOpenSample = async (sample) => {
    try {
      const response = await axios.get(apiUrl + `/get-sample-germ-data/${sample.sample_id}`);
      const responseData = response.data;
      setSampleRowItems(responseData.sample_items);
      setOpenSample(sample);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDateFilter = async () => {
    try {
      const response = await axios.get(apiUrl + `/get-samples-germ-pag`, {
        params: {
          page: 1,
          start_date: startDate ? startDate.toISOString().split('T')[0] : null,
          end_date: endDate ? endDate.toISOString().split('T')[0] : null
        }
      });
      setSamples(response.data.samples);
      setTotalPages(response.data.pages);
      setCurrentPage(1); // Reset to first page
    } catch (error) {
      console.error(error);
    }
  };

  const handleClearFilter = async () => {
    setStartDate(null);
    setEndDate(null);
    try {
      const response = await axios.get(apiUrl + `/get-samples-germ-pag`, {
        params: {
          page: 1
        }
      });
      setSamples(response.data.samples);
      setTotalPages(response.data.pages);
      setCurrentPage(1); // Reset to first page
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageClick = async (pageNumber) => {
    setCurrentPage(pageNumber);
    try {
      const response = await axios.get(apiUrl + `/get-samples-germ-pag`, {
        params: {
          page: pageNumber,
          start_date: startDate ? startDate.toISOString().split('T')[0] : null,
          end_date: endDate ? endDate.toISOString().split('T')[0] : null
        }
      });
      setSamples(response.data.samples);
      setTotalPages(response.data.pages);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseSample = () => {
    setOpenSample(null);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          className='btn btn-primary'
          key={i}
          onClick={() => handlePageClick(i)}
          style={{ fontWeight: i === currentPage ? "bold" : "normal" }}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  const handleDeleteSample = (deletedSampleId) => {
    const updatedSamples = samples.filter(sample => sample.sample_id !== deletedSampleId);
    setSamples(updatedSamples);
  };

  const fetchAllFilteredSamples = async () => {
    try {
      const response = await axios.get(apiUrl + `/get-germ-samples-all`, {
        params: {
          start_date: startDate ? startDate.toISOString().split('T')[0] : null,
          end_date: endDate ? endDate.toISOString().split('T')[0] : null
        }
      });
      return response.data.samples;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const generateCSV = async () => {
    const allSamples = await fetchAllFilteredSamples();
    const csvData = allSamples.map(sample => ({
      Muestra: sample.name,
      Usuario: sample.username,
      Fecha: new Date(sample.time).toLocaleDateString(),
      Planta: sample.plant,
      Variedad: sample.variety,
      Notas: sample.notes,
      Activo: sample.active_percentage
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", "filtered_samples.csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="samples-container">
      {openSample ? (
        <SampleGerminationInfo sampleInfo={openSample} onCloseSample={handleCloseSample} sampleRowItems={sampleRowItems} />
      ) : (
        <div className='samples-view-container'>
          <div className='samples-view-title'>
            <h2>{t('samples_view_capacity_history')}</h2>
            <div className='date-picker-container'>
              {t('samples_view_from')}
              <DatePicker
                id="start-date-picker"
                className='picker'
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                isClearable
                placeholderText={t('samples_view_start_date')}
              />
              {t('samples_view_to')}
              <DatePicker
                id="end-date-picker"
                className='picker'
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                isClearable
                placeholderText={t('samples_view_end_date')}
              />
              <button className='btn btn-primary filter' onClick={handleDateFilter}>
                {t('samples_view_filter')}
              </button>
              <button className='btn btn-secondary filter' onClick={handleClearFilter}>
                {t('samples_view_clear_filter')}
              </button>
              <button className='btn btn-secondary download' onClick={generateCSV}>
                {t('samples_view_download_csv')}
              </button>
            </div>
          </div>
          <div className='samples-rows'>
            {samples.map((sample) => (
              <SampleGerminationRow
                key={sample.sample_id}
                sample={sample}
                openSample={() => handleOpenSample(sample)}
                deleteSample={handleDeleteSample}
              />
            ))}
          </div>
          <div className='page-container'>
            {renderPageNumbers()}
          </div>
        </div>
      )}
    </div>
  );
};

export default SamplesGerminationContainer;