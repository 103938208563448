import React, { useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../styles/auth.css'
import apiUrl from '../config.js';
import { useTranslation } from 'react-i18next';
import logoImage from '../assets/malteriapng.png';
import logoLatitud from '../assets/logo-latitud.png';


export default function (props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate()
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { t } = useTranslation();
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
      const response = await axios.post(apiUrl + '/login', {
        email,
        password,
      });

      const data = response.data;
      if (response.status === 200) {
        // Authentication successful
        localStorage.setItem("token", data.token);
        // Redirect to the protected route (e.g., Portal component)
        setIsLoggingIn(true); // Start the fade-out effect
        setTimeout(() => {
          navigate("/portal"); // Navigate after the fade-out completes
        } , 500);
      } else {
        // Authentication failed
        setError("Invalid email or password");
      }
    } catch (error) {
      console.error("Error during login:", error);
      setError("Invalid email or password");
    }
    return false;
  };

  return (
    <div className={`Auth-form-container ${isLoggingIn ? 'fade-out' : ''}`}>
      
      <form id='auth-form' className="Auth-form" onSubmit={handleSubmit}>
        <div className="Auth-form-content">
          <div className="logo-container">
            <img  src={logoImage} alt="Logo" className="logo-icon-auth" />
            <div className="logo-separator"></div>
            <img  src={logoLatitud} alt="Logo" className="logo-icon-auth-latitud" />
          </div>
          <h3 className="Auth-form-title">{t('login_sign_in')}</h3>
          <div className="form-group mt-3">
            <label>{t('login_email_address')}
              <input
                type="email"
                id="email-input"
                className="form-control mt-1"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            
          </div>
          <div className="form-group mt-3">
            <label>{t('login_password')}
              <input
                id="password-input"
                type="password"
                className="form-control mt-1"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>
            
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
            {t('login_submit')}
            </button>
          </div>
          {error && <p className="text-danger mt-2">{error}</p>} {/* Display the error message */}
        </div>
      </form>
    </div>
  )
}