import './App.css';
import Portal from './components/Portal.js'
import "bootstrap/dist/css/bootstrap.min.css"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import Auth from "./components/Auth.js"

import './i18n';

const hasValidToken = () => {
  // Check if the JWT token exists in local storage and if it's valid
  const token = localStorage.getItem("token");
  // Add any additional checks for token validity, if needed
  return !!token; // Return true if token exists and is truthy, otherwise false
};

const PrivateRoute = ({ element }) => {
  // Check if the user has a valid token before rendering the protected route
  return hasValidToken() ? element : <Navigate to="/" replace />;
};


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path="/portal" element={<PrivateRoute element={<Portal />} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
