import React from 'react';
import { useTranslation } from 'react-i18next';
import es_flag from './assets/es.png'
import en_flag from './assets/en.png'
import './App.css'

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const getButtonClassName = (lng) => {
    return `language-button ${currentLanguage.startsWith(lng) ? 'active-language' : 'inactive-language'}`;
  };

  return (
    <div>
      <button className={getButtonClassName('es')} onClick={() => changeLanguage('es')}>
        <img className='flag' src={es_flag} alt="ES"  />
      </button>
      <button className={getButtonClassName('en')} onClick={() => changeLanguage('en')}>
        <img className='flag' src={en_flag} alt="EN"  />
      </button>
    </div>
  );
}

export default LanguageSwitcher;