import React, { useState } from 'react';
import '../styles/sampleRow.css';
import view_icon from '../assets/view_icon.jpg';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import apiUrl from '../config.js';

const SampleGerminationRow = ({sample, openSample, deleteSample}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const localTime = new Date(sample.time).toLocaleString('es-UY', {
    timeZone: 'America/Montevideo', 
    year: 'numeric', 
    month: 'numeric', 
    day: 'numeric', 
    hour: '2-digit', 
    minute: '2-digit'
  });
  const [fadeOut, setFadeOut] = useState(false);

  const handleOpenGermSample = () => {
    setLoading(true);
    openSample().finally(() => setLoading(false));
  };

  const handleDeleteSample = () => {
    const isConfirmed = window.confirm(t('sample_info_delete_confirm'));
    if (isConfirmed) {
      setFadeOut(true); // Trigger the fade-out animation
      setTimeout(() => {
      deleteSampleCall(sample.sample_id)
        .then(() => {
          deleteSample(sample.sample_id);
        })
        .catch(error => {
          // Handle error in deletion
          console.error("Failed to delete sample:", error);
        })
        .finally(() => setLoading(false));
      }, 500); // Duration should match your CSS animation duration
    }
  };

  const deleteSampleCall = async (sampleId) => {
    try {
      const response = await axios.delete(apiUrl + `/delete-germination-sample/${sampleId}`);
      return response.data; // or handle the response as needed
    } catch (error) {
      console.error('Error deleting the sample:', error);
      throw error; // rethrow the error to be handled by the caller
    }
  };

  return (
    <div className={`sample-image-row ${fadeOut ? 'fade-out' : ''}`}>
      <div className="sample-image-id"> {sample.name}</div>
      <div className="sample-image-id"> {sample.username}</div>
      <div className="sample-image-date"> {localTime}</div>
      <div className="sample-image-capacity">{t('sample_info_capacity')} {sample.active_percentage} %</div>
      <button className="btn btn-danger sample-image-delete" onClick={handleDeleteSample} >
        {t('sample_info_delete')}
      </button>
      <button  className=" btn btn-primary sample-image-open" onClick={handleOpenGermSample} disabled={loading}>
        {loading ? t('sample_info_loading') : t('sample_info_open')}
      </button>
    </div>
  );
};

export default SampleGerminationRow;
