import React, { useState, useEffect } from 'react';
import '../styles/newSample.css'
import axios from 'axios';
import apiUrl from '../config.js';
import { useTranslation } from 'react-i18next';

const NewSample = ({ onSampleCreate, sampleType }) => {
  const [name, setName] = useState('');
  const [time, setTime] = useState(getLocalTimeForInput());
  const [batch, setBatch] = useState('');
  const [notes, setNotes] = useState('');
  const [variety, setVariety] = useState('');
  const [varietyList, setVarietyList] = useState([]); // Store varieties list
  const [customVariety, setCustomVariety] = useState(''); // State for custom variety input
  const [plant, setPlant] = useState(''); // Add state for plant
  const [remitentNumber, setRemitentNumber] = useState(''); // Add state for remitentNumber
  const [smpType, setSampleType] = useState(sampleType);
  const [errorMessage, setErrorMessage] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    fetchVarieties();
  }, []);

  const fetchVarieties = async () => {
    try {
      const response = await axios.get(apiUrl + '/varieties');
      setVarietyList(response.data);
    } catch (error) {
      console.error('Error fetching varieties:', error);
    }
  };

  function getLocalTimeForInput() {
    const now = new Date();
    const timeZone = 'America/Montevideo'; // Time zone for Uruguay

    let localTime = now.toLocaleDateString('en-CA', { timeZone: timeZone }) + 'T' + 
                    now.toLocaleTimeString('en-CA', { timeZone: timeZone, hour12: false });

    // If you want to cut off seconds and milliseconds:
    localTime = localTime.slice(0, -3);

    return localTime;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Convert local time to UTC for the server.
    const localTime = new Date(time);
    const utcTime = localTime.toISOString().slice(0, 19).replace('T', ' ');

    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('time', utcTime);
      formData.append('notes', notes);
      formData.append('variety', variety === 'other' ? customVariety : variety);
      formData.append('type', smpType);
      formData.append('plant', plant); // Add plant to formData
      if (sampleType === 'New Sample') {
        formData.append('batch', batch);
      } else {
        formData.append('remitent_number', remitentNumber); // Add remitentNumber to formData
      }

      const token = localStorage.getItem('token');

      const response = await axios.post(apiUrl + '/create-sample', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }); // Update the endpoint to /upload

      if (response.data.isProcessing) {
        // If another processing is ongoing, set the error message
        setErrorMessage(t('new_sample_error'));
      } else {
        onSampleCreate({
          sample_id: response.data.sample_id,
          name: name,
          time: time,
          batch: batch,
          type: smpType
        });
      }

    } catch (error) {
      console.error('Error creating sample:', error);
    }

  };

  return (
    <div className="form-container">
      <h2 className="title">
        {sampleType === 'New Sample' ? t('new_sample_title_hydration') : t('new_sample_title_capacity')}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </h2>
      <form id="newsample-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="sampleName">{t('new_sample_name')}</label>
          <input
            type="text"
            id="sampleName"
            value={name}
            onChange={(event) => setName(event.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="time">{t('new_sample_date')}</label>
          <input
            type="datetime-local"
            id="time"
            value={time}
            onChange={(event) => setTime(event.target.value)}
            required
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="plant">{t('new_sample_plant')}</label>
          <input
            type="text"
            id="plant"
            value={plant}
            onChange={(event) => setPlant(event.target.value)}
          />
        </div>

        {sampleType === 'New Sample' && (
          <div className="form-group">
            <label htmlFor="batch">{t('new_sample_batch')}</label>
            <input
              type="text"
              id="batch"
              value={batch}
              onChange={(event) => setBatch(event.target.value)}
              required
            />
          </div>
        )}

        {sampleType !== 'New Sample' && (
          <div className="form-group">
            <label htmlFor="remitentNumber">{t('new_sample_remitent_number')}</label>
            <input
              type="text"
              id="remitentNumber"
              value={remitentNumber}
              onChange={(event) => setRemitentNumber(event.target.value)}
            />
          </div>
        )}

        <div className="form-group">
          <label className='variety-label' htmlFor="variety">{t('new_sample_variety')}</label>
          <select
            className='variety-select'
            id="variety"
            value={variety}
            onChange={(e) => setVariety(e.target.value)}
            required
          >
            <option value="">{t('variety_select')}</option>
            {varietyList.map(v => (
              <option key={v.id} value={v.id}>{v.name}</option>
            ))}
            <option value="other">{t('Other')}</option>
          </select>
          {variety === 'other' && (
            <input
              type="text"
              className="custom-variety-input"
              placeholder={t('variety_new')}
              value={customVariety}
              onChange={(e) => setCustomVariety(e.target.value)}
              required
            />
          )}
        </div>

        <div className="form-group">
          <label htmlFor="notes">{t('new_sample_observations')}</label>
          <textarea
            className="observations-textarea"
            type="text"
            id="notes"
            value={notes}
            onChange={(event) => setNotes(event.target.value)}
            rows={4}
          />
        </div>

        <button className='btn btn-primary' type="submit">{t('new_sample_create')}</button>
      </form>
    </div>
  );
};

export default NewSample;
