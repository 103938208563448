import React, { useState } from 'react';
import '../styles/menu.css'
import svgHome from '../assets/home.svg'
import svgPlus from '../assets/plus-circle.svg'
import svgSearch from '../assets/search.svg'
import svgUsers from '../assets/users.svg'
import svgExit from '../assets/log-out.svg'
import logoImage from '../assets/logo.png';
import logoMalteria from '../assets/logo-malteria.png';
import logoLatitud from '../assets/logo-latitud.png';
import iconVariety from '../assets/leaf_line_icon.svg';

import LanguageSwitcher from '../LanguageSwitcher';
import { useTranslation } from 'react-i18next';


const Menu = ({onMenuClick}) => {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState('Home');

  const handleClick = (item) => {
    
    setActiveItem(item);
    onMenuClick(item);
  };
  return (
    <div className="left-menu">
        
        {/* Menu Items */}
        <div className="menu-language">
          <LanguageSwitcher/>
        </div>
        <div className="menu-logo">
          <img src={logoMalteria} alt="Logo" className="logo-icon" />
          <img src={logoLatitud} alt="Logo" className="logo-icon" />
          <h1 className="menu-title">{t('menu_title')}</h1>
        </div>
        <ul>
          <li className={activeItem === 'Home' ? 'active' : ''}  onClick={() => handleClick('Home')}><img src={svgHome} alt="Home" className="menu-icon" />{t('menu_home')}</li>
          <li className={activeItem === 'New Sample' ? 'active' : ''} onClick={() => handleClick('New Sample')}><img src={svgPlus} alt="Home" className="menu-icon" />{t('menu_hydration_analysis')}</li>
          <li className={activeItem === 'New Germination Sample' ? 'active' : ''} onClick={() => handleClick('New Germination Sample')}><img src={svgPlus} alt="Home" className="menu-icon" />{t('menu_germination_analysis')}</li>
          <li className={activeItem === 'View Samples' ? 'active' : ''} onClick={() => handleClick('View Samples')}><img src={svgSearch} alt="Home" className="menu-icon" />{t('menu_view_hydration_samples')}</li>
          <li className={activeItem === 'View Germination Samples' ? 'active' : ''} onClick={() => handleClick('View Germination Samples')}><img src={svgSearch} alt="Home" className="menu-icon" />{t('menu_view_germination_samples')}</li>
          <li className={activeItem === 'Varieties Manager' ? 'active' : ''} onClick={() => handleClick('Varieties Manager')}> <img src={iconVariety} alt="View Users" className="menu-icon variety" />{t('variety_menu')}
        </li>
          <li className={activeItem === 'Users' ? 'active' : ''} onClick={() => handleClick('Users')}><img src={svgUsers} alt="View Users" className="menu-icon" />{t('menu_users')}</li>
          <li onClick={() => onMenuClick('Exit')}><img src={svgExit} alt="Home" className="menu-icon" />{t('menu_exit')}</li>
        </ul>
      </div>
  );
};

export default Menu;