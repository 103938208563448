import React, { useState } from 'react';
import SampleGermImageRow from './SampleGermImageRow.js'
import '../styles/sampleImageItem.css'
import axios from 'axios'
import apiUrl from '../config.js';
import { useTranslation } from 'react-i18next';

const SampleGerminationInfo = ({ sampleInfo, onCloseSample, sampleRowItems}) => {

  const { t } = useTranslation();

  const [capacity, setCapacity] = useState(sampleInfo.active_percentage);

  const handleCapacityUpdate = async () => {
    try {
      const response = await axios.get(apiUrl + `/get-sample-capacity/${sampleInfo.sample_id}`);
      setCapacity(response.data.data.active_percentage);
    } catch (error) {
      console.error(error);
    }

  }

  const handleDownloadCSV = async () => {
    if (!sampleInfo || sampleInfo.length === 0) {
      console.error('Sample information is not available.');
      return;
    }
  
    try {
      const sample_id = sampleInfo.sample_id;
      const response = await axios.get(apiUrl + `/download-germination-sample-csv/${sample_id}`, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `sample_${sample_id}.csv`;
      link.click();
    } catch (error) {
      console.error(error);
    }
  };
  

  return (
    <div>
      <div className="sample-view-header">
        <div className='sample-view-info'>
          <div className="sample-info-name">
            <h4>{t('sample_info_name')}  {sampleInfo.name}</h4>
          </div>
          <div className="sample-info-batch">
            <h5>{t('sample_info_plant')}  {sampleInfo.plant}</h5>
          </div>
          <div className="sample-info-batch">
            <h5>{t('sample_info_variety')}  {sampleInfo.variety}</h5>
          </div>
        </div>
        <div className="sample-info-hydration">
          <h4>{t('sample_info_capacity')}  {capacity} %</h4>
          <h5>{t('sample_info_username')}  {sampleInfo.username} </h5>
        </div>
      </div>
      <div className="sample-images-row">
      {sampleRowItems.map((sample, index) => (
        <SampleGermImageRow key={sample.image_id} sample={sample} handleCapacityUpdate={handleCapacityUpdate} index={index} sampleItems={sampleRowItems}/>
      ))}
      </div>
      <div className="actions-container">
        <button className='btn btn-primary' onClick={onCloseSample}>{t('sample_info_close')}</button>
        <button className='btn btn-primary' onClick={handleDownloadCSV}>Download CSV</button>
        
        {sampleInfo.notes && (
          <div className='notes-container'>
            <h6>{t('sample_info_notes')}</h6>
            <textarea className='notes'           
              value={sampleInfo.notes}
              readOnly // Making it read-only if it's just for display
              rows="4" 
              cols="50"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SampleGerminationInfo;
