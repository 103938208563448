import React, { useState, useEffect } from 'react';
import '../App.css'; // Import your custom CSS file for styling
import NewSample from './NewSample.js';
import AddImages from './AddImages.js';
import SampleImagesContainer from './SampleImagesView.js';
import SampleGermImagesContainer from './SampleGermImagesView.js';
import SamplesContainer from './SamplesView.js';
import SamplesGerminationContainer from './SamplesGerminationView.js';
import ManageUsers from './ManageUsers.js';
import Home from './Home.js';
import Menu from './Menu.js';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import apiUrl from '../config.js';
import VarietiesManager from './VarietiesManager.js';


const Portal = () => {
  const [isVisible, setIsVisible] = useState(false); 
  const [activeComponent, setActiveComponent] = useState('Home'); // State to track the active component
  const [activeSampleData, setActiveSampleData] = useState({
    sample_id: '',
    name: '',
    time: '',
    batch: ''
  });
  const [sampleItems, setSampleItems] = useState([]);
  const [sampleInfo, setSampleInfo] = useState([]);
  const [samples, setSamples] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate()

  const [germinationKey, setGerminationKey] = useState(0);
  const [hydrationKey, setHydrationKey] = useState(0);

  useEffect(() => {
    // Set the portal to be visible after the component mounts
    setIsVisible(true);
  }, []);
  
  const handleMenuClick = (componentName) => {
    
    if (componentName == 'Exit'){
      localStorage.removeItem("token");
      navigate('/')
    }
    if (componentName == 'View Samples') {
      setHydrationKey(prevKey => prevKey + 1);
      handleSamplesView()
    }
     else if (componentName == 'View Germination Samples') {
      setGerminationKey(prevKey => prevKey + 1);
      handleSamplesGermView()
    } else {
      setActiveComponent(componentName);
    } 
  };

  const handleSampleCreate = (formInfo) => {
    
    setActiveSampleData((prevData) => ({
      ...prevData,
      ...formInfo
    }));
    changeComponent('Add Images');
  };

  const handleSampleProcess = async (sampleType, sample_id) => {
    
    const token = localStorage.getItem("token");

    // Include the token in the request headers
    const headers = {
      'Authorization': `Bearer ${token}`
    };

    if (sampleType == 'New Sample') {
      try {
        const response = await axios.get(apiUrl + `/get-sample-images/${sample_id}`, {headers: headers});
        // Parse the JSON response
        const responseData = response.data;
        
        // Access the sample_items and sample_info
        setSampleItems(responseData.sample_items);
        setSampleInfo(responseData.sample_info);
  
        changeComponent('Sample View');
  
        } catch (error) {
          console.error(error);
        }
    } else {
      try {
        const response = await axios.get(apiUrl + `/get-germ-sample-images/${sample_id}`, {headers: headers});
        // Parse the JSON response
        const responseData = response.data;
        // Access the sample_items and sample_info
        setSampleItems(responseData.sample_items);
        setSampleInfo(responseData.sample_info);
  
        changeComponent('Sample Germination View');
  
        } catch (error) {
          console.error(error);
        }
    }
  };

  const handleSamplesView = async () => {
    try {
      const response = await axios.get(apiUrl + '/get-samples-pag?page=1');
      setSamples(response.data.samples);
      setTotalPages(response.data.pages);
      changeComponent('View Samples');
    } catch (error) {
      console.error(error);
    }
  };

  const handleSamplesGermView = async () => {
    try {
    
      const response = await axios.get(apiUrl + '/get-samples-germ-pag?page=1');
      setSamples(response.data.samples);
      setTotalPages(response.data.pages);
      changeComponent('View Germination Samples');
    } catch (error) {
      console.error(error);
    }
  };

  // Function to handle component change
  const changeComponent = (componentName) => {
    
    setActiveComponent(componentName);
  };

  return (
    <div className={`landing-page-container ${isVisible ? 'visible' : ''}`}>
      {/* Left Menu */}
      <Menu onMenuClick={handleMenuClick} />
        

      {/* Content Area */}
      <div className="content-area">
        {/* Render different components based on activeComponent */}
        {activeComponent === 'Home' && <Home />}
        {activeComponent === 'New Sample' && <NewSample onSampleCreate={handleSampleCreate} sampleType={activeComponent}/>}
        {activeComponent === 'New Germination Sample' && <NewSample onSampleCreate={handleSampleCreate} sampleType={activeComponent}/>}
        {activeComponent === 'Add Images' && <AddImages activeSampleData={activeSampleData} onSampleProcess={handleSampleProcess} />}
        {activeComponent === 'Sample View' && <SampleImagesContainer activeSampleData={activeSampleData} sampleItems={sampleItems} sampleInfo={sampleInfo}/>}
        {activeComponent === 'Sample Germination View' && <SampleGermImagesContainer activeSampleData={activeSampleData} sampleItems={sampleItems} sampleInfo={sampleInfo}/>}
        {activeComponent === 'View Samples' && <SamplesContainer key={hydrationKey}  sampleItems={samples} totalPage={totalPages} />}
        {activeComponent === 'View Germination Samples' && <SamplesGerminationContainer key={germinationKey}  sampleItems={samples} totalPage={totalPages} />}
        {activeComponent === 'Varieties Manager' && <VarietiesManager />}
        {activeComponent === 'Users' && <ManageUsers  />}
        {activeComponent === 'About' && <AboutComponent />}
        {activeComponent === 'Contact' && <ContactComponent />}
      </div>
    </div>
  );
};

const AboutComponent = () => {
  return <h2>About Component</h2>;
};

const ContactComponent = () => {
  return <h2>Contact Component</h2>;
};

export default Portal;
